'use strict';
var LazyLoad = require('vanilla-lazyload'),
    MobileDetect = require('mobile-detect');
var siteGlobal = {
  initReady: function () {
    this.deviceDetect();
    this.inputWrap();
  },
  initLoad: function () {
    this.langsMenu();
    this.subvoicesMenu();
    this.menuDataAtt();
    this.lazyLoad();
    this.homeVisual();
    this.homeVisualScroll();
    this.changeTrColor();
    this.labelAppear();
    this.labelPin();
    this.homeLabelChange();
    this.forbiciAnim();
    this.fearlessAnim();
    this.palaAnim();
    this.homeWinesAnim();

    this.cousinsIntroScrollAnim();
    this.cousinsAnim();
    this.realVenetoAnim();
    this.wineFamAnim();

    this.wineBgChange();
    this.whatBelievePathAnim();
    this.whatBelieveEls();

    this.bottlesSlider();
    this.bottlesAnim();
    this.dataAnim();
    siteGlobal.wineTransitionIn();

    /* Funzioni in actionFooter.php */
    hoverEffect();
    lottieAnim();


    if($('.page_wine').length === 0){
      $('body').css('background-color','transparent');
    }

    // changeClass();
  },
  initResize: function(){
  },
  initResizeEnd: function(){
    this.deviceDetect();
  },
  initScroll: function(){
    this.headerClass();
  },
  onScroll: function(){
    // this.homeBgColor();
  },
  deviceDetect: function(){
    let detector = new MobileDetect(window.navigator.userAgent);

    if (detector.mobile()) {
      $('body').removeClass('no-touch').addClass('is-touch');
    } else {
      $('body').removeClass('is-touch').addClass('no-touch');
    }
  },
  langsMenu: function(){
    $('.header__langs .active').on('click', function(event){
      event.stopPropagation();
      event.preventDefault();
      $(this).siblings('.arrow').toggleClass('rot');
      $(this).siblings('.lang-sub-menu').slideToggle(150);
    })
  },
  subvoicesMenu: function(){
    $('.header__wBlock').on('click', function(event){
      if ($(this).find('.menu-sub-voices').css('display') !== 'flex'){
        event.stopPropagation();
        event.preventDefault();
      }

      // const ALTEZZALINK = 35;
      // $(this).find('.menu-sub-voices').css('height',$(this).find('.menu-sub-voices').find('a').length * ALTEZZALINK)
      $(this).find('.arrow').toggleClass('rot');
      $(this).find('.menu-sub-voices').slideToggle(150).css('display','flex');
    })
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true,
        threshold: 600
    })
  },
  headerClass: function(){
    if ($(window).scrollTop() > 20) {
      $('.header').addClass('scrolled');
    } else {
      $('.header').removeClass('scrolled');
    }
  },
  menuInit: function(){
    $('.ham').on('click', function() {
      $('.ham, .header__menuWrap, .header__logo').toggleClass('active');
    })
  },
  menuDataAtt: function(){
    /* aqua */
    $('.home-menu a').attr({"data-col-1": '#FF6A6A', "data-col-2": '#FCCD32', "data-col-3": '#3AD0CE'});
    /* pink */
    $('.who-we-are-menu a').attr({"data-col-1": '#FF6A6A', "data-col-2": '#FCCD32', "data-col-3": '#DB3E9E'});
    /* red */
    $('.what-we-believe-menu a').attr({"data-col-1": '#FCCD32', "data-col-2": '#DB3E9E', "data-col-3": '#FF6A6A'});
    /* yellow */
    $('.our-vineyards-menu a').attr({"data-col-1": '#FF6A6A', "data-col-2": '#DB3E9E', "data-col-3": '#FCCD32'});
    /* yellow */
    $('.wines-menu a').attr({"data-col-1": '#FF6A6A', "data-col-2": '#DB3E9E', "data-col-3": '#FCCD32'});
    /* aqua */
    $('.say-hallo-menu a').attr({"data-col-1": '#FF6A6A', "data-col-2": '#FCCD32', "data-col-3": '#3AD0CE'});
  },
  changeTrColor: function(){
    $('a').on('click', function(){
      let col1 = $(this).data('col-1'),
          col2 = $(this).data('col-2'),
          col3 = $(this).data('col-3');
      $('#gradient1 stop').attr('stop-color', col1);
      $('#gradient2 stop').attr('stop-color', col2);
      $('#gradient3 stop').attr('stop-color', col3);

      setTimeout(function(){
        $('html').css('background-color', col3);
      }, 300)

    })
  },
  menuAnim: function(){
    var lc_items = $('.header__menuCol--menu nav ul li a'),
        rc_items = $('.header__wBlock .header__wTit, .header__wBlock .header__wmItem, .header__wBlock .arrow'),
        menuSep = $('.header__menuSep'),
        headerBot = $('.header__soc'),
        headerLangs = $('.header__langs'),
        controller = new ScrollMagic.Controller(),
        tl = new TimelineMax({ delay: 1 });

        var menuOpenTween = tl.to(menuSep, .3, {scale:1, x:"-50%", y:"-50%", ease: Sine.easeOut})
                          .staggerTo(rc_items, .5, {y: 0, opacity: 1, ease: Sine.ease}, .06, "-=.2")
                          .staggerTo(lc_items, .6, {y: 0, opacity: 1, ease: Sine.ease}, .12, "-=1.5")
                          .to(headerBot, .6, {opacity:1, y: 0, ease: Sine.easeOut}, "-=.4")
                          .to(headerLangs, .6, {opacity:1, y: 0, ease: Sine.easeOut}, "-=.5"),

            scene = new ScrollMagic.Scene().setTween(menuOpenTween).addTo(controller);
  },
  menuClose: function(){

    /* Rimuovo la classe al menu attivo */

    setTimeout(function(){
      $('#menu').removeClass('active');
    }, 250);

    let menuCols = $('.header__menuCol'),
        lc_items = $('.header__menuCol--menu').find('nav a'),
        rc_items = $('.header__wBlock .header__wTit, .header__wBlock .header__wmItem, .header__wBlock .arrow'),
        menuSep = $('.header__menuSep'),
        headerBot = $('.header__soc'),
        headerLangs = $('.header__langs'),
        controller = new ScrollMagic.Controller(),
        tl = new TimelineMax({ onStart: function(){
          siteGlobal.changeTrColor();
        }, onComplete: function(){
          // siteGlobal.changeTrColor();
        }}),

        menuCloseTween = tl.to(menuCols, .3, {opacity: 0, y: "-30px", ease: Power0.easeNone})
                           .to(headerBot, .3, {opacity:0, y: 30, ease: Sine.easeOut}, "-=.3")
                           .to(headerLangs, .3, {opacity:0, y: 30, ease: Sine.easeOut}, "-=.3")
                           .to(menuSep, .2, {scale:0, x:"-50%", y:"-50%", ease: Sine.easeOut}, "-=.2")
                           .staggerTo(rc_items, .1, {y: "100px", opacity: 0, ease: Sine.ease}, .01)
                           .staggerTo(lc_items, .1, {y: "70px", opacity: 0, ease: Sine.ease}, .01)
                           .to(menuCols, .3, {opacity: 1, y: "0", ease: Power0.easeNone}),

        scene = new ScrollMagic.Scene().setTween(menuCloseTween).addTo(controller);
  },
  homeVisual:function(){
    let homeImg = $('.homeVisual__img'),
        homeClaim = $('.homeVisual__claim'),
        homeTitSpan = $('.page_home .homeVisual__tit span'),
        homeTit = $('.page_home .homeVisual__tit div:nth-of-type(odd) i'),
        homeTit2 = $('.page_home .homeVisual__tit div:nth-of-type(even) i'),
        controller = new ScrollMagic.Controller(),
        tl = new TimelineMax({onComplete: function(){
            homeWriteAnim();
        }}),
        visualFade = tl.to(homeImg, 1.4, {scale: 1, opacity:1, x:"-50%", y: "-50%", ease: Power0.easeNone})
                       .to(homeTitSpan, .2, {opacity:1,ease: Sine.easeInOut}, "-=.2")
                       .to(homeTit, .5, {scaleX:1, ease: Sine.easeInOut}, "-=.7")
                       .to(homeTit2, .5, {scaleX:1, ease: Sine.easeInOut, transformOrigin:"center left"}, "-=.7"),
        scene = new ScrollMagic.Scene().setTween(visualFade).addTo(controller);
  },
  homeVisualScroll:function(){
    let homeImg = $('.homeVisual__img'),
        controller = new ScrollMagic.Controller(),
        height = $(window).outerHeight(true),
        claim = $('#home-claim'),
        visualTit = $('.page_home .homeVisual__tit'),
        tl = new TimelineMax(),
        visualZoom = tl.to(claim, 1.2, {top: "49%", ease: Power0.easeNone})
                       .to(visualTit, 1, {top: -150, ease: Power0.easeNone}, "-=2")
                       .to(homeImg, 1, {opacity: 0, ease: Power0.easeNone}, "-=2")
                       .set(homeImg, {css:{height:0}}),
        scene = new ScrollMagic.Scene({ triggerElement: $('#home-trigger')[0], duration: height}).setTween(visualZoom).addTo(controller);
  },
  forbiciAnim: function(){
    var forbici = $('.cousins').find('#forbici'),
        claim = $('.cousins__claimImg'),
        text = $('#lab_cont .homeSec__text'),
        tl = new TimelineMax(),
        controller = new ScrollMagic.Controller();

    var duration = ($(window).outerWidth() > 992) ? $(window).outerWidth(true) * 2 : $(window).outerWidth(true);

    if ($(window).outerWidth() > 768) {
      var forbiciTween = tl.to(forbici, .8, {left:0, ease: Sine.easeInOut})
                         .to(claim, .4, {top: 0, opacity:1, ease: Sine.easeIn}, "-=.3")
                         .to(text, .4, {opacity:1, y:0, ease: Sine.easeIn}, "+=.3")
                         .to(forbici, .8, {rotation:0, ease: Expo.easeInOut}, "-=.7")
                         .to(claim, .2, {top: "-5vh", opacity:0, ease: Sine.easeIn}, "+=.5")
                         .to(text, .2, {top: "-5vh", opacity:0, ease: Sine.easeIn}, "+=.3")
                         .to(forbici, .8, {left:"-80vw", rotation: 90, ease: Sine.easeInOut}, "-=.2"),
        pinScene01 = new ScrollMagic.Scene({ triggerElement: '#lab',triggerHook:0, duration: duration}).setPin("#lab_cont").setTween(forbiciTween).addTo(controller);
    } else {
      var forbiciTween = tl.to(forbici, .8, {left:0, ease: Sine.easeInOut})
                         .to(claim, .4, {top: 0, opacity:1, ease: Sine.easeIn}, "-=.3")
                         .to(text, .4, {opacity:1, y:0, ease: Sine.easeIn}, "+=.3")
                         .to(forbici, .8, {rotation:0, ease: Expo.easeInOut}, "-=.7")
                         .to(claim, .2, {top: "-5vh", opacity:0, ease: Sine.easeIn}, "+=.5")
                         .to(text, .2, {top: "-5vh", opacity:0, ease: Sine.easeIn}, "+=.3")
                         .to(forbici, .8, {left:"-110vw", rotation: 90, ease: Sine.easeInOut}, "-=.2"),
        pinScene01 = new ScrollMagic.Scene({ triggerElement: '#lab', triggerHook:0, duration: $(window).outerWidth(true) * 2}).setPin("#lab_cont").setTween(forbiciTween).addTo(controller);
    }
  },
  fearlessAnim: function(){
    var martello = $('#martello'),
        fearlessTit = $('.fearless__claim'),
        comics = $('.fearless__comicTop, .fearless__comicBottom'),
        text = $('#fearless .homeSec__text, #fearless .homeSec__btn'),
        tl = new TimelineMax(),
        controller = new ScrollMagic.Controller();
    var duration = ($(window).outerWidth() > 992) ? $(window).outerWidth(true) * 2 : $(window).outerWidth(true);

    if ($(window).outerWidth() > 768) {
      var forbiciTween = tl.to(martello, .8, {left:0, rotation: 0, ease: Sine.easeInOut})
                        .to(fearlessTit, .4, {top:"50vh", opacity: 1, ease: Sine.easeInOut}, "-=.7")
                        .to(comics, .15, {opacity:1, ease: Sine.easeIn})
                        .to(fearlessTit, .6, {top:"45vh", opacity:0, ease: Sine.easeIn}, "+=1.8")
                        .staggerTo(text, .3, {top:0, opacity:1, ease: Sine.easeIn}, .2)
                        .to(comics, .8, {opacity:0, ease: Sine.easeInOut}, "+=.5")
                        .staggerTo(text, .3, {opacity:0, top: "-5vh", ease: Sine.easeOut}, .2, "+=.3")
                        .to(martello, .8, {left:"-75vw", rotation: -360, ease: Sine.easeInOut}, "-=.8"),
      pinScene02 = new ScrollMagic.Scene({ triggerElement: '#fearless',  duration: duration}).setPin("#fearless_cont").setTween(forbiciTween).addTo(controller);
    } else {
      var forbiciTween = tl.to(martello, .8, {left:0, rotation: 0, ease: Sine.easeInOut})
                        .to(fearlessTit, .4, {top:"50vh", opacity: 1, ease: Sine.easeInOut}, "-=.7")
                        .to(comics, .15, {opacity:1, ease: Sine.easeIn})
                        .to(fearlessTit, .6, {top:"45vh", opacity:0, ease: Sine.easeIn}, "+=1.8")
                        .staggerTo(text, .3, {top:0, opacity:1, ease: Sine.easeIn}, .2)
                        .to(comics, .8, {opacity:0, ease: Sine.easeInOut}, "+=.5")
                        .staggerTo(text, .3, {opacity:0, top: "-5vh", ease: Sine.easeOut}, .2, "+=.3")
                        .to(martello, .8, {left:"-75vw", rotation: -180, ease: Sine.easeInOut}, "-=.8"),
      pinScene02 = new ScrollMagic.Scene({ triggerElement: '#fearless', duration: $(window).outerWidth(true) * 1.5}).setPin("#fearless_cont").setTween(forbiciTween).addTo(controller);
    }
  },
  palaAnim: function(){
    var pala = $('#pala'),
        claim = $('.embrace__claim'),
        arrows = $('.embrace__claimArrows'),
        text = $('.embrace .homeSec__text, .embrace .homeSec__btn'),
        tl = new TimelineMax(),
        controller = new ScrollMagic.Controller();
    var duration = ($(window).outerWidth() > 992) ? $(window).outerWidth(true) * 2.8 : $(window).outerWidth(true) * 1.5;

        if ($(window).outerWidth() > 768) {
          var palaTween = tl.to(claim, .4, {top: "50vh", opacity:1, ease: Sine.easeIn})
                          .to(pala, .4, {opacity: 1, scale:1, rotation: 180, ease: Sine.easeIn}, "-=.5")
                          .to(claim, .4, {top: "40vh", opacity:0, ease: Sine.easeOut}, "+=.2")
                          .staggerTo(text, .3, {opacity:1, top:0, ease: Sine.easeIn}, "-=.2")
                          .to(arrows, 2, {opacity: 1, scale:1, rotation: 180, ease: Expo.easeInOut}, "-=1")
                          .staggerTo(text, .3, {opacity:0, top:"-5vh", ease: Sine.easeIn}, "+=1")
                          .to(pala, .3, {opacity: 0, scale:0, ease: Sine.easeIn}, "-=.2")
                          .to(arrows, .3, {opacity: 0, scale:0, ease: Expo.easeInOut}, "-=.2"),
            pinScene03 = new ScrollMagic.Scene({ triggerElement: '#embrace', duration: duration}).setPin("#embrace_cont").setTween(palaTween).addTo(controller);
        } else {
            var palaTween = tl.to(claim, .4, {top: "50vh", opacity:1, ease: Sine.easeIn})
                          .to(pala, .4, {opacity: 1, scale:1, rotation: 180, ease: Sine.easeIn}, "-=.5")
                          .to(claim, .4, {top: "40vh", opacity:0, ease: Sine.easeOut}, "+=.2")
                          .staggerTo(text, .3, {opacity:1, top:0, ease: Sine.easeIn}, "-=.2")
                          .to(arrows, 2, {opacity: 1, scale:1, rotation: 180, ease: Expo.easeInOut}, "-=1")
                          .staggerTo(text, .3, {opacity:0, top:"-5vh", ease: Sine.easeIn}, "+=1")
                          .to(pala, .3, {opacity: 0, scale:0, ease: Sine.easeIn}, "-=.2")
                          .to(arrows, .3, {opacity: 0, scale:0, ease: Expo.easeInOut}, "-=.2"),
            pinScene03 = new ScrollMagic.Scene({ triggerElement: '#embrace', duration: $(window).outerWidth(true) * 2}).setPin("#embrace_cont").setTween(palaTween).addTo(controller);
        }
  },
  labelPin: function(){
    if ( $(window).outerWidth(true) > 768 ) {
      var controller = new ScrollMagic.Controller(),
          height = $('#lab').outerHeight(true) + $('#fearless').outerHeight(true) + $('#embrace').outerHeight(true) + $('#homeWines').outerHeight(true) + $('.fc').outerHeight(true),

          pinScene01 = new ScrollMagic.Scene({ triggerElement: '#lab', triggerHook: 0, duration: height}).setPin("#home_lab").setTween().addTo(controller);
    }
  },
  labelAppear: function(){
    let lab = $('#home_lab'),
        controller = new ScrollMagic.Controller(),
        tl = new TimelineMax(),
        labTween = tl.to(lab, .8, {opacity:1, ease: Sine.easeInOut}),
        scene = new ScrollMagic.Scene({triggerElement: $('#lab_trig')[0], duration: 150}).setTween(labTween).addTo(controller);
  },
  homeLabelChange: function(){
    if ( $(window).outerWidth(true) > 768 ) {
      var scrollMagicController = new ScrollMagic.Controller(),
          homeSections = ['#embrace','#homeWines','.fc'];

      $(homeSections).each( function(){
        var label = $(this).data('label'),
            labTxt = $('#home_lab_text'),
            tl = new TimelineMax(),
            labelChange = tl.to(labTxt, .2, {top: "-35px"}).to(labTxt, .2, { text: label }).to(labTxt, .2, {top: 0}),
            scene1 = new ScrollMagic.Scene({ triggerElement: $(this)[0], triggerHook: 0}).setTween(labelChange).addTo(scrollMagicController);
      })
    }
  },
  homeWinesAnim: function(){
    $('.wl').each(function () {
        var blockBg = $(this).find('.wl__bg'),
        trig = $(this).find('.wl__trig'),
        controller = new ScrollMagic.Controller(),
        tl = new TimelineMax(),
        homeWinesAnim = tl.to(blockBg, 4, { top: "35%", ease: Power0.easeNone }),
        scene = new ScrollMagic.Scene({ triggerElement: $(trig)[0], duration: $(window).outerHeight(true) * 1.8}).setTween(homeWinesAnim).addTo(controller);
      });
  },
  cousinsIntroScrollAnim:function(){
    let homeImg = $('#cous-intro .cousinsIntro__bg img'),
        controller = new ScrollMagic.Controller(),
        height = $(window).outerHeight(true),
        claim = $('#cous-intro .cousinsIntro__claim'),
        tl = new TimelineMax(),
        visualZoom = tl.to(claim, 1.2, {top: "0%", ease: Power0.easeNone})
                       .to(homeImg, 1.5, {opacity: 0, top: "120%", ease: Power0.easeNone}, "-=1.5"),
        scene = new ScrollMagic.Scene({ triggerElement: $('#cousins_intro_trig')[0], duration: height}).setTween(visualZoom).addTo(controller);
  },
  cousinsAnim: function(){
    $('.cousins').each(function(){
      var faces = $(this).find('.cousins__faces'),
          face_text = $(this).find('.cousins__textWrap'),
          face_wrap = $(this).find('.cousins__faceWrap'),
          icons = $(this).find('.cousins__icons'),
          pinnedEl = $(this).find('.cousins__cont'),
          leftCalc = $(window).outerWidth() / 2,
          tl = new TimelineMax(),
          controller = new ScrollMagic.Controller();

          if ($(window).outerWidth(true) > 767) {

            /* Versione 1 */

             // var facesTween = tl.to(faces, 1, {left: 0, ease: Sine.easeInOut})
             //             .to(icons, 1.8, {top: "-100vh", ease: Sine.easeInOut}, "-=1")
             //             .to(face_text, .3, {opacity:1, y: 0, ease: Sine.easeInOut}, "-=.3")
             //             .to(face_text, .3, {opacity:0, y: "-30", ease: Sine.easeInOut}, "+=.5")
             //             .to(faces, 1.3, {left: "-90vw", ease: Sine.easeInOut}),
             //      pinScene01 = new ScrollMagic.Scene({ triggerElement: $(this)[0], triggerHook: 0, duration: $(window).outerHeight(true) + 100}).setPin($(pinnedEl)[0]).setTween(facesTween).addTo(controller);



             /* Versione 2 */

            // var facesTween = tl.to(icons, 6, {top: "-100vh", ease: Sine.easeInOut}, "-=.5")
            //             .to(faces, 2, {left: 0, ease: Sine.easeInOut}, "-=5" )
            //             .to(face_text, .6, {opacity:1, y: 0, ease: Sine.easeInOut}, "-=2")
            //             .to(face_text, .6, {opacity:0, y: "-30", ease: Sine.easeInOut}, "+=.5")
            //             .to(faces, 2, {left: "-90vw", ease: Sine.easeInOut}),
            //       pinScene01 = new ScrollMagic.Scene({ triggerElement: $(this)[0], triggerHook: 0, duration: $(window).outerHeight(true) + 300}).setPin($(pinnedEl)[0]).setTween(facesTween).addTo(controller);


             /* Versione 3 */

              var facesTween = tl.to(icons, 6, {top: "-100vh", ease: Sine.easeInOut}, "-=.5")
                        .to(faces, 3, {left: 0, ease: Sine.easeInOut}, "-=5" )
                        .to(face_text, .6, {opacity:1, y: 0, ease: Sine.easeInOut}, "-=2")
                        .to(face_text, .6, {opacity:0, y: "-30", ease: Sine.easeInOut}, "+=.5")
                        .to(faces, 2, {left: "-90vw", ease: Sine.easeInOut}),
                  pinScene01 = new ScrollMagic.Scene({ triggerElement: $(this)[0], triggerHook: 0, duration: $(window).outerHeight(true) + 300}).setPin($(pinnedEl)[0]).setTween(facesTween).addTo(controller);
          } else {
              var facesTween = tl.to(icons, 6, {top: "-100vh", ease: Sine.easeInOut}, "-=1")
                         .to(faces, 2, {left: 0, ease: Sine.easeInOut}, "-=4.5")
                         .to(face_text, 1, {opacity:1, y: 0, ease: Sine.easeInOut}, "-=3")
                         .to(face_text, 1, {opacity:0, y: "-30", ease: Sine.easeInOut}, "+=.5")
                         .to(faces, 3, {left: "-90vw", ease: Sine.easeInOut}),
                pinScene01 = new ScrollMagic.Scene({ triggerElement: $(this)[0], triggerHook: 0, duration: 1000}).setPin($(pinnedEl)[0]).setTween(facesTween).addTo(controller);
          }
    })
  },
  realVenetoAnim: function(){
    let homeImg = $('#real-veneto-intro .homeSec__bg'),
        controller = new ScrollMagic.Controller(),
        height = $(window).outerHeight(true) * 1.2,
        claim = $('#real-veneto-intro .cousinsIntro__claim'),
        tl = new TimelineMax(),
        visualZoom = tl.to(claim, 2, {top: "-70%", ease: Power0.easeInOut})
                       .to(homeImg, 1.7, {opacity: 0, top: "-120vh", ease: Power0.easeInOut}, "-=2"),
        scene = new ScrollMagic.Scene({ triggerElement: $('#real_veneto_trig')[0], duration: height}).setTween(visualZoom).addTo(controller);
  },
  wineFamAnim: function(){
    let homeImg = $('#iwf-intro .homeSec__bg'),
        controller = new ScrollMagic.Controller(),
        height = $(window).outerHeight(true) * 1.2,
        claim = $('#iwf-intro .cousinsIntro__claim'),
        tl = new TimelineMax(),
        visualZoom = tl.to(claim, 2, {top: "-70%", ease: Power0.easeInOut})
                       .to(homeImg, 1.7, {opacity: 0, top: "-120vh", ease: Power0.easeInOut}, "-=2"),
        scene = new ScrollMagic.Scene({ triggerElement: $('#iwf_trig')[0], duration: height}).setTween(visualZoom).addTo(controller);
  },
  wineBgChange: function(){
    if($('.page_wine').length){
      let pageColor = $('.wineList__el').data('bg');
      $('body').css('background-color',pageColor);
    }
  },
  whatBelievePathAnim:function(){
    let pathOver = $('.belSec__pathOver'),
        controller = new ScrollMagic.Controller(),
        height = $('.belSec').outerHeight(true) - 150,
        tl = new TimelineMax(),
        believeAnim = tl.to(pathOver, 1.8, {top: "70vh", ease: Sine.easeInOut}),
        scene = new ScrollMagic.Scene({triggerHook: 0}).setTween(believeAnim).addTo(controller);
  },
  whatBelieveEls: function(){
      $('.belSec__block').each(function () {
        let block = $(this).find('.belSec__blockWrap'),
            blockBg = $(this).find('.belSec__bg'),
            trig = $(this).find('.belSec__trig'),
            controller = new ScrollMagic.Controller(),
            tl = new TimelineMax(),
            believeAnim = tl.to(blockBg, 4, { top: -120, ease: Power0.easeNone }),
            scene = new ScrollMagic.Scene({ triggerElement: $(trig)[0], duration: $(window).outerHeight(true) * 1.8}).setTween(believeAnim).addTo(controller);
      });
  },
  bottlesSlider: function(){


    if ($(window).outerWidth(true) > 767) {

      const MARGIN = 1100;
      const wineWidth = $('.wineCar__slider .wineCar__sl').eq(0).outerWidth();
      var special_slider_w = $('#special .wineCar__slider .wineCar__sl').length * wineWidth + MARGIN;
      var daily_slider_w = $('#daily .wineCar__slider .wineCar__sl').length * wineWidth + MARGIN;
      var bubbles_slider_w = $('#bubbles .wineCar__slider .wineCar__sl').length * wineWidth+ MARGIN;

      $('#special .wineCar__slider').css('width', special_slider_w)
      var anim_w = (special_slider_w * -1) + $(window).outerWidth(true) ;
      var sl_slider = $('#special .wineCar__slider'),
          tl = new TimelineMax(),
          controller = new ScrollMagic.Controller(),
          forbiciTween = tl.to(sl_slider, 1, {left: anim_w, ease: Sine.easeInOut}),
          bottlesPin = new ScrollMagic.Scene({ triggerElement: '#special', triggerHook: 0, duration: $(window).outerHeight(true) * 3}).setPin('#special').setTween(forbiciTween).addTo(controller);

      $('#daily .wineCar__slider').css('width', daily_slider_w)
      var anim_w = (daily_slider_w * -1) + $(window).outerWidth(true) ;
      var sl_slider = $('#daily .wineCar__slider'),
          tl = new TimelineMax(),
          controller = new ScrollMagic.Controller(),
          forbiciTween = tl.to(sl_slider, 1, {left: anim_w, ease: Sine.easeInOut}),
          bottlesPin = new ScrollMagic.Scene({ triggerElement: '#daily', triggerHook: 0, duration: $(window).outerHeight(true) * 3}).setPin('#daily').setTween(forbiciTween).addTo(controller);

          $('#bubbles .wineCar__slider').css('width', bubbles_slider_w)
          var anim_w = (bubbles_slider_w * -1) + $(window).outerWidth(true) ;
      var sl_slider = $('#bubbles .wineCar__slider'),
          tl = new TimelineMax(),
          controller = new ScrollMagic.Controller(),
          forbiciTween = tl.to(sl_slider, 1, {left: anim_w, ease: Sine.easeInOut}),
          bottlesPin = new ScrollMagic.Scene({ triggerElement: '#bubbles', triggerHook: 0, duration: $(window).outerHeight(true) * 3}).setPin('#bubbles').setTween(forbiciTween).addTo(controller);
    }
  },
  bottlesAnim: function(){
    let bottle = $('.wineList__bottle'),
        claim1 = $('.wineList__galBlock--left img'),
        claim2 = $('.wineList__galBlock--right img'),
        bg = $('.wineList__bg'),
        controller = new ScrollMagic.Controller(),
        height = $(window).outerHeight(true),
        tl = new TimelineMax(),
        visualZoom = tl.to(claim1, 2.4, {top: "-70%", ease: Sine.easeIn}, "-=3")
                       .to(claim2, 1.8, {top: "-90%", ease: Sine.easeIn}, "-=3")
                       .to(bottle, 3, {top: "-50%", ease: Sine.easeOut}, "-=3"),
        scene = new ScrollMagic.Scene({ triggerElement: $('#wineList__trig')[0], duration: height}).setTween(visualZoom).addTo(controller);
  },
  dataAnim: function(){
    let mask = $('.wineSheet__mask'),
        controller = new ScrollMagic.Controller(),
        height = $(window).outerHeight(true),
        tl = new TimelineMax(),
        maskAnim = tl.staggerTo(mask, .45, {left: "10px", ease: Sine.easeOut}, .1),
        scene = new ScrollMagic.Scene({ triggerElement: $('.wineSheet__trig')[0]}).setTween(maskAnim).addTo(controller);
  },
  wineTransitionOut: function(){
    let write = $('.wineList__galBlock'),
        bottle = $('.wineList__bottle'),
        sheet = $('.wineSheet'),
        bg = $('.wineList__bg'),
        controller = new ScrollMagic.Controller(),
        tl = new TimelineMax();

        var bottleOut = tl.staggerTo(write, 1, {y: 50, opacity: 0, ease: Power4.easeIn}, .2)
                          .to(bg, 1, {scale:.8, opacity: 0, ease: Power4.easeIn}, "-=1")

                          .to(bottle, 1, {y: 50, opacity: 0, ease: Power4.easeIn}, "-=1")
                          .to(sheet, 1, {y: 50, opacity: 0, ease: Power4.easeIn}, "-=1"),
            scene = new ScrollMagic.Scene().setTween(bottleOut).addTo(controller);
  },
  wineTransitionIn: function(){
    let write = $('.wineList__galBlock'),
        bottle = $('.wineList__bottle'),
        sheet = $('.wineSheet'),
        bg = $('.wineList__bg'),
        controller = new ScrollMagic.Controller(),
        tl = new TimelineMax();

        var bottleOut = tl.to(bg, 1, {scale:1, opacity: .4, ease: Power4.easeOut})
                          .staggerTo(write, 1, {y: 0, opacity: 1, ease: Power4.easeOut}, .2, "-=1")
                          .to(bottle, 1, {y: 0, opacity: 1, ease: Power4.easeOut}, "-=1.2")
                          .to(sheet, 1, {y: 0, opacity: 1, ease: Power4.easeOut}, "-=1.5"),
            scene = new ScrollMagic.Scene().setTween(bottleOut).addTo(controller);
  },
  inputWrap: function(){
    $('[name="privacy"]').each(function(){
      $(this).after('<span class="check_wrap"></span>');
    });
  },
  rebuildAllEvents: function(){
    // initSketch();
    menuClickInit();
    // this.homeBgColor();
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;

(function () {

  siteGlobal.initReady();

  $( window ).on('load', function() {
    $('#preloader').addClass('loaded');
    // siteGlobal.initLoad();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });


  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }
  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

  $(window).on('scroll', function(){
    siteGlobal.onScroll();
  })
}());



